import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';

import img1 from '../../../assets/images/brands/lays.jpeg';
import img2 from '../../../assets/images/brands/veeba.jpeg';
import img3 from '../../../assets/images/brands/epigamia.jpg';
import img4 from '../../../assets/images/brands/haldiram.jpeg';
import img5 from '../../../assets/images/brands/hershey.jpeg';
import img6 from '../../../assets/images/brands/nuttygritties.jpg';
import img7 from '../../../assets/images/brands/parle.jpeg';
import img8 from '../../../assets/images/brands/pepsico.jpeg';
import img9 from '../../../assets/images/brands/britannia.jpeg';
import img10 from '../../../assets/images/brands/cookieman.jpeg';
import img11 from '../../../assets/images/brands/raw-pressery.jpg';
import img12 from '../../../assets/images/brands/dabur.jpeg';
import img13 from '../../../assets/images/brands/carra-choclates.jpg';
import img14 from '../../../assets/images/brands/nestle.png';
import img15 from '../../../assets/images/brands/diversey.jpg';

const brands = [
    { id: 1, imgPath: img1 },
    { id: 2, imgPath: img2 },
    { id: 3, imgPath: img3 },
    { id: 4, imgPath: img4 },
    { id: 5, imgPath: img5 },
    { id: 6, imgPath: img6 },
    { id: 7, imgPath: img7 },
    { id: 8, imgPath: img8 },
    { id: 9, imgPath: img9 },
    { id: 10, imgPath: img10 },
    { id: 11, imgPath: img11 },
    { id: 12, imgPath: img12 },
    { id: 13, imgPath: img13 },
    { id: 14, imgPath: img14 },
    { id: 15, imgPath: img15 }
];

const Images = () => {
    const [sliderNum, setSliderNum] = useState(6);

    useEffect(() => {
        const screenWidth = window.screen.width;

        if (screenWidth > 1450) {
            setSliderNum(7);
        } else if (screenWidth > 1000) {
            setSliderNum(6);
        } else if (screenWidth > 768) {
            setSliderNum(5);
        } else if (screenWidth > 500) {
            setSliderNum(4);
        } else if (screenWidth < 500) {
            setSliderNum(3);
        }
    }, []);

    return (
        <div>
            <div className="spacer" id="imgs-component">
                <Container>
                    <Row className="justify-content-center m-b-30">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Brands</h1>
                            <h6 className="subtitle">We are proud to offer a variety of brands to our valued customers.</h6>
                        </Col>
                    </Row>
                    <Swiper
                        slidesPerView={sliderNum}
                        spaceBetween={30}
                        grabCursor={true}
                        loop={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: true,
                        }}
                        modules={[Autoplay]}
                        className="mySwiper">
                        {brands.map((brand) => (
                            <SwiperSlide key={brand.id}>
                                <div className="">
                                    <img src={brand.imgPath} className="rounded img-responsive" alt="brands" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </div>
        </div>
    );
};

export default Images;
