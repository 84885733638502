import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Components from "./views/components/components.jsx";
import PrivacyPolicy from "./views/PrivacyPolicy.jsx";
import LegalDisclaimer from "./views/LegalDisclaimer.jsx";
import TermsAndConditions from "./views/TermsAndConditions.jsx";
import VendingMachines from "./views/VendingMachines.jsx";
import ComingSoon from "./views/ComingSoon.jsx";
import CoffeeVending from "./views/portfolios/CoffeeVending.jsx";
import ContactUs from "./ContactUs/ContactUs.jsx";
import AboutUs from "./AboutUs/AboutUs.jsx";
import CorporateGifting from "./views/portfolios/CorporateGifting.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

var hist = createBrowserHistory();
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Components />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
      <Route path="/vending-machines" element={<VendingMachines />} />
      <Route path="/coffee-vending-machines" element={<CoffeeVending />} />
      <Route path="/corporate-gifting" element={<CorporateGifting />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
