import React from "react";
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import { Container, Row, Col } from "reactstrap";
import img from '../assets/images/contactus/contact-us-1280.jpg';

const ComingSoon = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
        <div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Contact Us</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="m-b-40">
                <Col md="6">
                    <img className="card-img-top" src={img} alt="Contact Us" />
                    </Col>
                <Col lg="6" md="7" className="align-self-center">
                                    <h6 className="subtitle">Thank you for your interest in Steam Ideas and for trusting us with your corporate needs. We're here to assist you every step of the way. Whether you have a question, need more information, or are ready to start a project, please feel free to reach out to us.</h6>
                                    <p><ul className="list-style-none">
                                    <li className="p-10">
                                    <i className="fa fa-phone"></i>
                                      <span className="btn-md">9350781439</span>
                                      </li>
                                      <li className="p-10">
                                      <i className="fa fa-envelope"></i>
                                      <span className="btn-md">info@bigmenu.in</span>
                                      </li><li className="p-10">
                                      <i className="fa fa-headphones"></i>
                                      <span className="btn-md">Monday to Saturday 9AM - 6PM</span>
                                      </li>
                                    </ul></p>
                                </Col>
                    
                </Row>
            </Container>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoon;
