import React from "react";
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import { Container, Row, Col } from "reactstrap";

const TermsAndConditions = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div>
            <div className="mt-20">
              <Container>
                <Row className="justify-content m-t-40">
                  <Col lg="12" md="10" className="">
                    <h1 className="title font-bold">Terms and Conditions</h1>
                    <p>Welcome to Steam Ideas! By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully before using our services.</p>
                    <p><strong>1. Services and Products</strong></p>
                    <p>Steam Ideas provides a range of corporate supplies, including office supplies, furniture, equipment, and vending machine services. All products and services are subject to availability and business approval.</p>
                    <p><strong>2. Orders and Payment</strong></p>
                    <p>All orders are subject to acceptance by Steam Ideas. Payment terms and conditions will be provided at the time of order.</p>
                    <p><strong>3. Limitation of Liability</strong></p>
                    <p>Steam Ideas will not be liable for any indirect, incidental, or consequential damages arising from the use of our products or services.</p>
                    <p><strong>4. Governing Law</strong></p>
                    <p>These Terms and Conditions are governed by the laws of India. Any disputes arising from these terms will be resolved in the courts located in Gurugram, Haryana.</p>
                    <p><strong>5. Changes to Terms</strong></p>
                    <p>We reserve the right to update or modify these Terms and Conditions at any time. Changes will be effective upon posting on our website.</p>

                    <h3>Refunds/Cancellations Policy</h3>
                    <p><strong>Refund Policy</strong></p>
                    <p>Please note that Steam Ideas does not offer refunds or cancellations for products or services. All transactions are subject to approval by Steam Ideas. For any issues or concerns, please contact us directly at <a href="mailto:info@bigmenu.in">info@bigmenu.in</a>.</p>

                    <h3>Shipping Policy</h3>
                    <p><strong>Shipping Timeline</strong></p>
                    <p>We aim to process and ship all orders within 2-3 working days from the date of order confirmation. Shipping times may vary based on the destination and availability of products.</p>
                    <p><strong>No Additional Charges</strong></p>
                    <p>There are no additional shipping charges applicable.</p>

                    <h3>Contact Us</h3>
                    <p>If you have any questions about these policies, please contact us:</p>
                    <p><strong>By email:</strong> <a href="mailto:info@bigmenu.in">info@bigmenu.in</a></p>
                    <p> Address: Gurugram, Haryana 122001, India</p>

                    <p>Thank you for visiting Steam Ideas!</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
