/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/features/feature13/img1.jpg';
import img2 from '../../../assets/images/features/feature13/img2.jpg';
import img3 from '../../../assets/images/features/feature13/img3.jpg';
import img4 from '../../../assets/images/features/feature13/img4.jpg';
// import img5 from '../../../assets/images/features/feature30/img1.jpg';
import img5 from '../../../assets/images/portfolio/vending-machine.png';


const FeatureComponent = () => {
    return (
        <div>
           
            <div className="spacer feature4">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                        <h2 className="title">How does it work?</h2>
                            <h6 className="subtitle">From selection to installation, our process is seamless—choose your machine, pick a rental plan, and enjoy fresh, cafe-quality coffee effortlessly.</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="3" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-coffee"></i></div>
                                    <h5 className="font-medium">Choose Your Machine</h5>
                                    <p className="m-t-20">Select the coffee vending machine that best suits your needs from our range of Café Coffee Day machines.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-calendar"></i></div>
                                    <h5 className="font-medium">Flexible Rental Plans</h5>
                                    <p className="m-t-20">Opt for a rental plan that fits your budget and usage requirements. Our monthly plans are designed to be cost-effective and hassle-free.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-cogs"></i></div>
                                    <h5 className="font-medium">Installation and Setup</h5>
                                    <p className="m-t-20">Our experts will deliver, install, and set up the machine at your location, ensuring it’s ready to use right away.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-heart"></i></div>
                                    <h5 className="font-medium">Enjoy Great Coffee</h5>
                                    <p className="m-t-20">Start serving delicious, fresh coffee to your employees and guests. We take care of the rest, from maintenance to refills.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className='m-t-40'>
                    <Row className="justify-content-center">
                        <Col md="10" className="text-center">
                            <h1 className="title">Get Started today</h1>
                            <h6 className="subtitle">Ready to bring the cafe experience to your workplace? Contact us today to learn more about our Café Coffee Day vending machines and to get started with a rental plan that works for you.</h6>
                            <h6 className='font-bold m-t-40'>Explore our range of Cafe Coffee Day vending machines and discover the perfect coffee solution for your office.</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default FeatureComponent;
