import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import img1 from '../../../assets/images/clients/boa.png';
import img2 from '../../../assets/images/clients/daikin.jpg';
import img3 from '../../../assets/images/clients/zomato.jpeg';
import img4 from '../../../assets/images/clients/uber.jpeg';
import img5 from '../../../assets/images/clients/expedia.jpeg';
import img6 from '../../../assets/images/clients/myntra.jpeg';
// import img7 from '../../../assets/images/clients/rbs.png';
import img8 from '../../../assets/images/clients/allianz.jpeg';
import img9 from '../../../assets/images/clients/airbnb.jpeg';
import img10 from '../../../assets/images/clients/ciena.jpeg';
import img11 from '../../../assets/images/clients/mercer.jpeg';
import img12 from '../../../assets/images/clients/mmt.jpeg';
// import img13 from '../../../assets/images/clients/tablespace.jpeg';
import img13 from '../../../assets/images/clients/concentrix.jpeg';
// import img15 from '../../../assets/images/clients/concentrix.jpeg';

const clients = [
    { imgPath: img1 },
    { imgPath: img2 },
    { imgPath: img3 },
    { imgPath: img4 },
    { imgPath: img5 },
    { imgPath: img6 },
    { imgPath: img8 },
    { imgPath: img9 },
    { imgPath: img10 },
    { imgPath: img11 },
    { imgPath: img12 },
    { imgPath: img13 }
];

const C2aComponent = () => {
    return (
        <div>
            <div className="mini-spacer bg-info text-white c2a7">
                <Container>
                    <div className="d-flex justify-content-between">
                        <div className="display-7 align-self-center">Unable to locate what you seek, reach out to us, and we will meet your needs.</div>
                        <div className="ms-auto m-t-10 m-b-10"><button className="btn btn-outline-light btn-md"><a href="tel:+919350781439">Contact Us</a></button></div>
                    </div>
                </Container>
            </div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Our Clients</h1>
                            {/*<h6 className="subtitle">Our satisfied clients speaks volumes. Below are just a few examples from our extensive list of happy clients.</h6>*/}
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <div className="col-lg-12 d-inline-flex">
                            <div className="row d-inline-flex no-block">
                                {clients.map((client, index) => (
                                    <Col sm="1" key={index}>
                                        <img src={client.imgPath} className="rounded img-responsive" alt="clients" />
                                    </Col>
                                ))}
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            
            <div className="spacer feature42 bg-info-gradiant">
                <Container>
                    <Row className="justify-content-center wrap-feature49-box">
                        <Col lg="9" md="10" className="text-center">
                            <h2 className="title text-white">About Steam Ideas</h2>
                            <h6 className="subtitle text-white op-7 m-b-20">Welcome to Steam Ideas, your one-stop solution for all your office needs. We are located in Gurugram, Haryana, and are dedicated to providing high-quality products and services to businesses of all sizes. With our extensive range of office supplies, furniture, equipment, and more, we aim to streamline your office operations and enhance productivity. Our team of experts is committed to delivering exceptional customer service and ensuring your satisfaction. Trust Steam Ideas to fulfill all your office requirements efficiently and effectively.

At Steam Ideas, we understand the importance of a well-equipped and functional workspace. Whether you need office stationery, technology solutions, or ergonomic furniture, we have got you covered. Our comprehensive product range is carefully curated to meet the diverse needs of modern businesses. With our convenient location in Gurugram, Haryana, we are easily accessible and ready to cater to your office needs. Experience the convenience of a one-stop solution for all your office requirements with Steam Ideas.</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default C2aComponent;
