import React from "react";
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import { Container, Row, Col } from "reactstrap";

const ComingSoon = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div>
            <div className="spacer mt-20">
            <Container>
                    <Row className="text-center m-t-40 justify-content-center">
                        <Col lg="8" md="10" className="">
                        <h1 className="title font-bold">Stay Tuned</h1>
    <p>Our full services page is coming soon, and we can’t wait to show you everything we have to offer. In the meantime, if you have any questions or need immediate assistance, please feel free to reach out to us.</p>

                        </Col>
                    </Row>
                </Container>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoon;
