import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";
import { Container, Row, Col } from 'reactstrap';


// sections for this page
import Collections from "../portfolios/corporate-gifting/collections.jsx";
// import FeatureComponent from "../portfolios/corporate-gifting/featurecomponent.jsx";


const Components = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                <div className="spacer" id="js-component">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="7" className="text-center">
                                <h1 className="title font-bold">Corporate Gifting</h1>
                                    <h6 className="subtitle">Discover the power of corporate gifting to strengthen bonds, celebrate milestones, and leave a lasting impression. Our curated selection of gifts is designed to reflect your brand’s values and make every recipient feel valued and appreciated.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Collections />
                {/* <FeatureComponent /> */}
                </div>
            </div>
            <Footer />
        </div>
    );
}

Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
