import React, { useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Container,
    Row,
    Col
} from 'reactstrap';

import img1 from '../../../assets/images/portfolio/coffeemachine.jpg';
import img2 from '../../../assets/images/portfolio/cappuccino.jpg';
import img3 from '../../../assets/images/portfolio/coffeeoffice.jpg';


const items = [
    {
        src: img1,
        altText: '',
        caption2: '',
        caption: ''
    },
    {
        src: img2,
        altText: '',
        caption2: '',
        caption: ''
    },
    {
        src: img3,
        altText: '',
        caption2: '',
        caption: ''
    },
];

const JsComponents = (props) => {

    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    const toggle1 = () => {
        setModal1(!modal1);
    }

    const toggle2 = () => {
        setModal2(!modal2);
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map(item => (
        <CarouselItem
            className="custom-tag"
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
        >
            <img className="w-100" src={item.src} alt={item.altText} />
            <CarouselCaption captionText={item.caption} captionHeader={item.caption2} />
        </CarouselItem>
    ));

    return (
        <div>
            <div className="spacer" id="js-component">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Coffee Vending Machines</h1>
                                <h6 className="subtitle">Experience the rich taste of freshly brewed coffee right in your office with our premium Café Coffee Day vending machines. Designed for convenience, these machines deliver café-quality coffee with every cup.</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="m-b-40">
                <Col lg="6" md="7" className="align-self-center">
                                    <h3 className="title font-bold">Why choose our coffee vending machines?</h3>
                                    <p className='m-t-40'></p>
                                        <h6><strong>Premium Quality Beverages</strong></h6>
                                        <p>Enjoy Café Coffee Day’s signature coffee blends, brewed to perfection using fresh milk and high-quality ingredients.</p>
                                        <h6><strong>Convenience and Efficiency</strong></h6> 
                                        <p>Our machines are user-friendly, ensuring that your employees and visitors can quickly grab their favorite coffee without any hassle.</p> 
                                        <h6><strong>Monthly Rental Plans</strong></h6>               
                                        <p> We offer flexible and affordable rental plans tailored to meet the needs of businesses of all sizes. Whether you’re a startup or a large enterprise, we have a solution for you.</p> 
                                        <h6><strong>Reliable Service and Support</strong></h6>
                                        <p>Our dedicated team ensures that your coffee machine is always in top condition, with regular maintenance and prompt support whenever needed.</p>
                                </Col>
                    <Col md="6">
                        <Carousel
                            activeIndex={activeIndex}
                            next={next.bind(null)}
                            previous={previous.bind(null)}
                        >
                            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex.bind(null)} />
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous.bind(null)} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next.bind(null)} />
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default JsComponents;
