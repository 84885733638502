import React from "react";
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import { Container, Row, Col } from "reactstrap";
import img from '../assets/images/aboutus/why-us.jpg';

const ComingSoon = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
<div className="spacer">
    <Container>
        <Row className="justify-content-center">
            <Col md="9" className="text-center">
            <h1 className="title font-bold">About Us</h1>

                <h6 className="subtitle">
                    Welcome to <strong>Steam Ideas</strong> - your trusted partner for comprehensive corporate solutions. Located in Gurugram, Haryana, we specialize in providing high-quality office supplies, furniture, and equipment to businesses of all sizes. Our mission is to simplify and enhance your corporate operations by offering a wide range of products and services tailored to meet your specific needs.
                </h6>
            </Col>
        </Row>
    </Container>
</div>

<div className="spacer bg-light">
    <Container>
        <Row className="justify-content-center">
            <Col md="9" className="text-center">
                <h2 className="section-title">Our Story</h2>
                <p>
                    Founded with a vision to be the ultimate one-stop solution for corporate supplies, Steam Ideas has grown into a leading provider in the industry. Our journey began with a commitment to quality, innovation, and customer satisfaction, values that continue to drive everything we do. Over the years, we have expanded our offerings to include a diverse range of products—from everyday office essentials to specialized solutions like corporate gifting and vending machine services.
                </p>
            </Col>
        </Row>
    </Container>
</div>

<div className="spacer p-b-0">
    <Container>
        <Row className="justify-content-center">
            <Col md="9" className="text-center">
                <h2 className="section-title">Our Philosophy</h2>
                <p>
                    We believe in creating value through exceptional service. At Steam Ideas, customer satisfaction is at the heart of everything we do. We work closely with our clients to understand their needs and deliver solutions that exceed expectations. Our team is dedicated to ensuring that your experience with us is seamless, efficient, and, most importantly, successful.
                </p>
            </Col>
        </Row>
    </Container>
</div>

<div className="spacer p-b-0">
<Container>
                <Row className="m-b-40">
                <Col lg="6" md="7" className="align-self-center">
                <h2 className="section-title">Why Choose Us?</h2>
                <h6><strong>Quality Assurance</strong></h6>
<p>We source only the best products from trusted manufacturers, ensuring that you receive top-notch quality every time.</p>

<h6><strong>Customized Solutions</strong></h6>
<p>No two businesses are the same, which is why we offer personalized services to meet your unique requirements.</p>

<h6><strong>Reliable Support</strong></h6>
<p>Our team of experts is always on hand to provide guidance, answer your questions, and offer support whenever you need it.</p>

                                </Col>
                                <Col md="6">
                    <img className="card-img-top" src={img} alt="Contact Us" />
                    </Col>
                </Row>
            </Container>
</div>

<div className="spacer p-b-0">
    <Container>
        <Row className="justify-content-center">
            <Col md="9" className="text-center">
                <h2 className="section-title">Our Commitment to Sustainability</h2>
                <p>
                    At Steam Ideas, we are committed to promoting sustainability in the workplace. We offer a selection of eco-friendly products and encourage responsible consumption practices among our clients. Together, we can make a positive impact on the environment.
                </p>
            </Col>
        </Row>
    </Container>
</div>

<div className="spacer">
    <Container>
        <Row className="justify-content-center">
            <Col md="9" className="text-center">
                <h2 className="section-title">Join Us in Our Journey</h2>
                <p>
                    As we continue to grow and evolve, our focus remains on delivering excellence in everything we do. Whether you're looking for reliable office supplies, innovative corporate gifts, or a complete overhaul of your workplace, Steam Ideas is here to support your success.
                </p>
            </Col>
        </Row>
    </Container>
</div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoon;
