import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/portfolio/vending.jpeg';
import img2 from '../../../assets/images/portfolio/vending-machine.jpg';
import img3 from '../../../assets/images/portfolio/housekeeping.jpg';
import img4 from '../../../assets/images/portfolio/office-catering.jpg';
import img5 from '../../../assets/images/portfolio/office-stationary.jpg';
import img6 from '../../../assets/images/portfolio/corporate-gifting.jpg';
import img7 from '../../../assets/images/portfolio/pantry-supplies.jpg';
import img8 from '../../../assets/images/portfolio/crockery.jpeg';
import img9 from '../../../assets/images/portfolio/disposables.jpg';

const portfolios = [
    {
        id: 1,
        title: "Snacks Vending Machines",
        imgPath: img1,
        text: "Modern snacks vending machines offering a variety of treats. ",
        link: '/vending-machines'
    },
    {
        id: 2,
        title: "Tea/Coffee Vending Machines",
        imgPath: img2,
        text: "We provide multiple options for tea/coffee vending machines. ",
        link: '/coffee-vending-machines'
    },
    {
        id: 3,
        title: "Corporate Gifting",
        imgPath: img6,
        text: "Our gifting services help you make a lasting impression on clients, partners, and employees.",
        link: '/corporate-gifting'
    },
    {
        id: 4,
        title: "Pantry Supplies",
        imgPath: img7,
        text: "We offer a wide range of products to fulfill your specific requirements.",
        link: '/coming-soon'
    },
    {
        id: 5,
        title: "Housekeeping",
        imgPath: img3,
        text: "We provide quality housekeeping products to maintain a clean and hygienic work environment.",
        link: '/coming-soon'
    },
    {
        id: 6,
        title: "Catering and Tuck Shops",
        imgPath: img4,
        text: "We provide tuck shop and catering services offering a variety of delicious and customizable menus.",
        link: '/coming-soon'
    },
    {
        id: 7,
        title: "Stationery",
        imgPath: img5,
        text: "We have everything to make your workplace well-equipped and organized.",
        link: '/coming-soon'
    },
    {
        id: 8,
        title: "Crockery",
        imgPath: img8,
        text: "Enhance your workplace with our elegant and durable office crockery.",
        link: '/coming-soon'
    },{
        id: 9,
        title: "Disposables",
        imgPath: img9,
        text: "Convenient and eco-friendly disposables for convenient and hygienic use.",
        link: '/coming-soon'
    },
];

const PortfolioComponent = () => {
    return (
        <div id="section1">
            <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="10" className="text-center">
                            <h6 className="subtitle">
                                Are you tired of juggling multiple vendors for your office supplies, gifting, furniture etc? Look no further than Steam Ideas, one-stop solution for all your office needs. Located in Gurugram, Haryana, we offer a wide range of products and services to keep your office running smoothly. Choose Steam Ideas for all your office needs and experience the convenience of a single, reliable supplier.
                            </h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">Our Services</h2>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        {portfolios.map((portfolio) => (
                            <Col md="4" key={portfolio.id}>
                                <Card className="card-shadow">
                                    <a href={portfolio.link} className="img-ho">
                                        <img className="card-img-top" src={portfolio.imgPath} alt={portfolio.title} />
                                    </a>
                                    <CardBody>
                                        <h5 className="font-medium m-b-10">{portfolio.title}</h5>
                                        <p className="m-b-0 font-14">{portfolio.text}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PortfolioComponent;
