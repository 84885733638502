/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/features/feature13/img1.jpg';
import img2 from '../../../assets/images/features/feature13/img2.jpg';
import img3 from '../../../assets/images/features/feature13/img3.jpg';
import img4 from '../../../assets/images/features/feature13/img4.jpg';
// import img5 from '../../../assets/images/features/feature30/img1.jpg';
import img5 from '../../../assets/images/portfolio/vending-machine.png';


const FeatureComponent = () => {
    return (
        <div>
           
            <div className="spacer feature4">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                        <h2 className="title">Features and Benefits</h2>

                            <h6 className="subtitle">Our Snacks Vending Machines offer a convenient way to keep everyone satisfied throughout the day. From savory snacks to sweet treats, our machines are stocked with a diverse range of products.</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="4" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-hand-pointer-o"></i></div>
                                    <h5 className="font-medium">Easy to use </h5>
                                    <p className="m-t-20">with smooth touch based User Interface.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-map-pin"></i></div>
                                    <h5 className="font-medium">Place it anywhere</h5>
                                    <p className="m-t-20">With our Slim Compact Design and
multiple Internet Connection Options.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-check-circle"></i></div>
                                    <h5 className="font-medium">Capacity </h5>
                                    <p className="m-t-20">30-50+ variety of items
stored in one machine </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="4" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-plug"></i></div>
                                    <h5 className="font-medium">Always available</h5>
                                    <p className="m-t-20">with regular 220V AC power supply.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-shopping-basket"></i></div>
                                    <h5 className="font-medium">Grab Multiple Items at One Go</h5>
                                    <p className="m-t-20">is possible in a single transaction.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className="wrap-feature4-box text-center">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-shield"></i></div>
                                    <h5 className="font-medium">RFID Configuration </h5>
                                    <p className="m-t-20">enables seamless and secure transactions.
stored in one machine </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className='m-t-40'>
                    <Row className="justify-content-center">
                        <Col md="10" className="text-center">
                            <h1 className="title">Get Started today</h1>
                            <h6 className="subtitle">Ready to upgrade your office with our top-of-the-line snacks vending machines? Contact us to learn more about our solutions and how we can tailor them to fit your workplace. Our team is here to assist you with installation, maintenance, and ongoing support, ensuring a seamless experience from start to finish.</h6>
                            <h6 className='font-bold m-t-40'>Experience the convenience and satisfaction of our Snacks Vending Machines – because a happy team is a productive team!</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default FeatureComponent;
